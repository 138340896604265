import React, { FunctionComponent, useContext, useEffect } from 'react';
import { StudentBaseInfo } from '/src/domain/shared/StudentBaseInfo';
import { Club } from '/src/domain/club/ClubData';
import {
  CurrentUserContext,
  CurrentUserContextProps,
} from '/src/contexts/shared/CurrentUserContext';
import { PostContext, PostContextProps } from '/src/contexts/PostContext';
import { Box } from '@mui/material';
import { PostView } from '/src/ui/studentTasks/studentTasks/posts/PostView';
import { PostsStub } from '/src/ui/dashboard/PostsStub';
import { useTranslation } from 'react-i18next';

interface RepostedPostsViewProps {
  repostsOwner: StudentBaseInfo;
  className?: string;
  userClub: Club | null;
}

export const RepostedPostsView: FunctionComponent<RepostedPostsViewProps> = ({
  repostsOwner,
  className,
  userClub,
}) => {
  const { t } = useTranslation();
  const { currentUser, currentDate } = useContext(
    CurrentUserContext,
  ) as CurrentUserContextProps;
  const { repostedPostsByStatus, requestPostsByStatus } = useContext(
    PostContext,
  ) as PostContextProps;

  useEffect(() => {
    requestPostsByStatus({
      type: "reposted",
      club: userClub,
      currentUserId: currentUser?.id,
      repostsOwnerId: repostsOwner.id,
    })
  }, []);

  return (
    <Box className={className}>
      {!!repostedPostsByStatus.posts?.length ? (
        repostedPostsByStatus.posts.map((p) => {
          if (!p.userid) return null;

          return (
            <PostView
              key={p.postid}
              {...p}
              type="post"
              currentDate={currentDate}
              isReadOnly={true}
              currentUserId={currentUser?.id}
              isPreview
              userInfo={p.userInfo}
              isRepostAvailable={Boolean(
                currentUser &&
                  currentUser?.id === p.userInfo?.currentTrainerId,
              )}
              isRepostedPost={Boolean(
                currentUser && currentUser.id == repostsOwner.id,
              )}
              isRepostPreview
              userClub={userClub}
            />
          );
        })
      ) : (
        <PostsStub sx={{ mt: '1rem' }} />
      )}
    </Box>
  );
};
