import { TrainerProfileData } from "/src/domain/trainerProfile/TrainerProfileData";
import { User } from "/src/domain/shared/User";
import { TrainersListData } from "/src/domain/trainersList/TrainersListData";
import {
  ChallengeRegistrationData,
  ChallengePaidRegistrationData,
} from "/src/domain/challenges/ChallengeRegistrationData";
import { ChallengeUser } from "/src/domain/challenges/ChallengeUser";
import { ChallengeProtocolData } from "/src/domain/challenges/ChallengeProtocolData";
import { ChallengeRatingData } from "/src/domain/challenges/ChallengeRatingData";
import { ChallengeRatingCupData } from "/src/domain/challenges/ChallengeRatingCup";
import { StudentTasksData } from "/src/domain/studentTasks/StudentTasksData";
import { PersonalStatisticsData } from "/src/domain/personalStatistics/PersonalStatisticsData";
import { UpcomingRacesContainerProps } from "/src/ui/shared/upcomingRaces";
import { ChallengeTeamData } from "/src/domain/challenges/ChallengeTeamData";
import { ChatWithTrainerData } from "/src/domain/chatWithTrainer/ChatWithTrainerData";
import { ChallengeUserRatingData } from "/src/domain/challenges/ChallengeUserRatingData";
import {
  StudentProfileData,
  StudentProfileUser,
} from "/src/domain/studentProfile/StudentProfileData";
import { AuthorizationPageData } from "/src/domain/authorization/AuthorizationPageData";
import {
  SupportPageData,
  SupportUser,
} from "/src/domain/support/SupportPageData";
import { ChallengeClubRatingData } from "/src/domain/challenges/ChallengeClubRatingData";
import { DashboardData } from "/src/domain/dashboard/DashboardData";
import { ChallengeStartlistData } from "/src/domain/challenges/ChallengeStartlistData";
import { ChartsData } from "/src/domain/charts/ChartsData";
import { links } from "./links";

export enum Page {
  TRAINER_PROFILE = "trainerProfile",
  TRAINERS_LIST = "trainersList",
  CHALLENGES_REGISTRATION = "challengesRegistration",
  CHALLENGES_PAID_REGISTRATION = "challengesPaidRegistration",
  CHALLENGE_PROTOCOL = "challengeProtocol",
  CHALLENGE_RATING = "challengeRating",
  CHALLENGE_RATING_CUP = "challengeRatingCup",
  STUDENT_TASKS = "studentTasks",
  PERSONAL_STATISTICS = "personalStatistics",
  UPCOMING_RACES = "upcomingRaces",
  CHALLENGE_TEAM = "challengeTeam",
  CHAT_WITH_TRAINER = "chatWithTrainer",
  CHALLENGE_USER_RATING = "challengeUserRating",
  AUTHORIZATION = "authorization",
  STUDENT_PROFILE = "studentProfile",
  SUPPORT = "support",
  LANDING_PAGE = "landingPage",
  CHALLENGE_CLUB_RATING = "challengeClubRating",
  NFT_EDIT = "nftEdit",
  DASHBOARD = "dashboard",
  QUEST = "quest",
  CHALLENGES_STARTLIST = "challengesStartlist",
  CHARTS = "charts",
  POST = "post",
  POSTS = "posts",
  PLAYLIST = "playlist",
  TAG = "tag",
  APPEALS = "appeals",
  APPEAL_CHAT = "appealChat",
  AUDIOS = "audios",
  CLUB = "club",
  CLUB_LEADERS = "clubLeaders",
  CLUB_PAYMENT = "clubPayment",
}

export interface PageData {}

export interface PageConfig {
  header: boolean;
  footer: boolean;
  authUrl: string;
  isChallengesLinkDisabled: boolean;
  challengesLink: string;
  predictions: boolean;
  shopBanner: boolean;
  extraFields?: ExtraRegistrationFields[];
  isWhiteLabel: boolean;
  chatLink?: string;
  timestamp?: number;
  addsDisabled?: boolean;
  isAppealDisabled?: boolean;
  isCommunityChatDisabled?: boolean;
  appealEmail?: string;
  appealLink?: string;
}

export enum ExtraRegistrationFields {
  CITY = "city",
  POSITION = "position",
  FIO = "fio",
  AGE = "age",
  DISTANCE = "distance",
  GENDER = "gender",
  UNIT = "unit",
  PREDICTION = "prediction",
  REGION = "region",
}

export const defaultPageConfig: PageConfig = {
  header: true,
  footer: true,
  authUrl: `${links.origin}/login?back=${window.location.href}`,
  isChallengesLinkDisabled: false,
  challengesLink: `${links.origin}/challenges#schedule`,
  predictions: true,
  shopBanner: true,
  extraFields: [],
  isWhiteLabel: false,
  addsDisabled: false,
  appealEmail: "appeal@s10.run",
  appealLink: `${links.origin}/support`,
};

declare global {
  interface Window {
    mountPage: (
      page: Page,
      pageData: TrainerProfileData &
        TrainersListData &
        ChallengeRegistrationData &
        ChallengePaidRegistrationData &
        ChallengeProtocolData &
        ChallengeRatingData &
        ChallengeRatingCupData &
        StudentTasksData &
        PersonalStatisticsData &
        UpcomingRacesContainerProps &
        ChallengeTeamData &
        ChatWithTrainerData &
        ChallengeUserRatingData &
        AuthorizationPageData &
        StudentProfileData &
        SupportPageData &
        ChallengeClubRatingData &
        DashboardData &
        ChallengeStartlistData &
        ChartsData,
      currentUser?: User & ChallengeUser & StudentProfileUser & SupportUser,
      pageConfig?: PageConfig
    ) => void;
    __locale: string;
    ym: (id: number, eventName: string, action: string) => void;
    fbq: (type: string, name: string) => void;
    _tmr: {type: string, id: number, goal: string}[];
    VK: {
      Goal: (event: string) => void;
    }
  }
}
